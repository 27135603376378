<template>
  <div class="login-container">
    <el-form
      class="login-form"
      ref="loginFromRef"
      :model="loginForm"
      :rules="loginRules"
    >
      <div class="title-container111">
        <div class="con_img">
          <img src="http://zct.yslts.com/files/0101.png" class="img">
        </div>
        <div class="userrongqi">
          <div class="title-container">
            <h3 class="title">用户登录</h3>
          </div>
          <!-- username -->
          <el-form-item prop="username">
        <span class="svg-container">
          <svg-icon icon="user" />
        </span>
            <el-input
              placeholder="username"
              type="text"
              v-model="loginForm.username"
            />
          </el-form-item>

          <!-- password -->
          <el-form-item prop="password">
        <span class="svg-container">
          <svg-icon icon="password" />
        </span>
            <el-input
              placeholder="password"
              :type="passwordType"
              v-model="loginForm.password"
            />
            <span class="show-pwd">
          <span class="svg-container" @click="onChangePwdType">
            <svg-icon
              :icon="passwordType === 'password' ? 'eye' : 'eye-open'"
            />
          </span>
        </span>
          </el-form-item>

          <!-- 登录按钮 -->
          <el-button
            class="denglu"
            type="primary"
            style="width: 100%;height: 30%; margin-top: 20px;padding-top: 20px;padding-bottom: 20px; font-size: 20px;"
            :loading="loading"
            @click="handleLogin"
          >
            登录</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { validatePassword } from './rules'
import { userStore } from '@/store/index'
const store = userStore()

// 数据源
const loginForm = ref({
  username: '', // ceshi
  password: '' // 123456college
})
// 验证规则
const loginRules = ref({
  username: [
    {
      required: true,
      trigger: 'blur',
      message: '用户名为必填项'
    }
  ],
  password: [
    {
      required: true,
      trigger: 'blur',
      validator: validatePassword()
    }
  ]
})

// 处理密码框文本显示
const passwordType = ref('password')
const onChangePwdType = () => {
  if (passwordType.value === 'password') {
    passwordType.value = 'text'
  } else {
    passwordType.value = 'password'
  }
}

// TODO 处理登录
const loading = ref(false)
// 获取 ref 实例
const loginFromRef = ref(null)
const handleLogin = () => {
  // 1：进行表单校验
  loginFromRef.value.validate(async (valid) => {
    if (!valid) return
    try {
      // 2：触发登录动作
      loading.value = true
      await store.login(loginForm.value)
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
    // 3：进行登录后处理
  })
}
</script>

<style lang="scss" scoped>
// 定义变量
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
$cursor: #000;

.login-container {
  min-height: 100%;
  width: 100%;
  background: url('http://zct.yslts.com/files/login_bg20240805.png');
  overflow: hidden;

  .login-form {
    position: relative;
    width: 60%;
    /*width: 520px;*/
    max-width: 100%;
    padding: 120px 35px 0;
    margin: 0 auto;
    overflow: hidden;

    ::v-deep .el-form-item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      color: #454545;
    }

    ::v-deep .el-input {
      display: inline-block;
      height: 47px;
      width: 85%;

      .el-input__inner {
        box-shadow: none !important;
        --el-select-input-focus-border-color: none !important;
      }

      .el-input__wrapper {
        background-color: transparent;
        box-shadow: none;
      }

      input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: #5a5e66;
        height: 47px;
        caret-color: $cursor;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    display: inline-block;
  }

  .title-container111 {
    position: relative;
    width: 100%;
    height: 100%;
    /*background: #fdfdfd;*/

    .con_img {
      /*margin-top: 2%;*/
      /*background: #fdfdfd;*/
      float: left;
      width: 50%;
      height: 50%;
      .img {
        width: 115%;
        height: 115%;
      }
    }
    .userrongqi{
      /*background: #fdfdfd;*/
      float: right;
      width: 37%;
      height: 37%;
      margin-top: 13%;
      margin-right: 10%;
      /*margin-top: 20%;*/
      .title-container {
        position: relative;
        margin-bottom: 50px;

        .title {
          font-size: 30px;
          color: #5a5e66;
          /*margin: 0px auto 40px auto;*/
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
